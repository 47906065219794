import React from "react";

const ContactPage = () => {
  return (
    <div className="d-flex align-center justify-center">
      <div>Contact page</div>
    </div>
  );
};

export default ContactPage;
